import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
// import LazyLoad from 'react-lazyload';

const useStyles = makeStyles({
  root: {
    // maxWidth: 345,
  },
});

export default function ImgMediaCard(img, title, date, onClick) {
  const classes = useStyles();
  // console.log(onClick)
  return (

    <Card className='card' square className={classes.root} style={{ height: '100%' }}>
      <CardActionArea style={{ height: '100%' }} onClick={onClick} className="aaaaa">        <CardMedia
          component="img"
          alt={title}
          height="240"
          // image={img.replace("images","images-thumb")}//読み込みを早くしたくて小さくしたけど、一旦コメントアウト
          image={img}
          title={title}
          style={{ objectPosition: "top", borderBottom: '1px solid #eee' }}
        />
        <CardContent align='left' op='op'>
          <Box fontSize={14}>
            <time>{date}</time>
          </Box>
          <Typography component="h3" variant='h6'>
            {title}
          </Typography>
        </CardContent>
      </CardActionArea>
      {/* <CardActions>
        <Button size="small" color="primary" onClick={handleModal()}>
          Share
        </Button>
      </CardActions> */}
    </Card >


  );
}