import React, { useState } from 'react';
import './App.css';
import ImgMediaCard from './cardComponent';
import Preloader from './imgPreloader';
import Grid from '@material-ui/core/Grid';
import works from './output'
import { makeStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Pagination from '@material-ui/lab/Pagination';
import CancelIcon from '@material-ui/icons/Cancel';
// import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import './reset.css';
import './style.scss';


window.onload = function () {
  Preloader();
}


const values = {
  xs: 0,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920,
};
const theme = createMuiTheme({
  typography: {
    fontFamily: [
      'Noto Sans',
      'sans-serif',
    ].join(','),
  },
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    up: key => `@media (min-width:${values[key]}px)`,
  },
});

const useStyles = makeStyles({
  contents: {
    maxWidth: 1020,
    margin: '0 auto',
  },
  modalWrap: {
    overflow: 'scroll',
  },
  bg: {
    background: 'white',
  }
});
function App() {
  return (
    <ThemeProvider theme={theme}>
      {/* <CssBaseline /> */}
      <div className="App">
        <Grid container className='aboveCon'>
          <Grid item xs={12} md={6} >
            <Header />
          </Grid>
          <Grid item xs={12} md={6}>
            <Discribe />
          </Grid>
        </Grid>
        <Contents />
      </div>
    </ThemeProvider>
  );
}
function Header() {

  return (
    <Grid component='header' container alignItems='flex-end' >
      <Grid item><Typography variant='h3' align='left' component='h1' ><Box pr={1}>Ryoma Yamaguchi</Box></Typography></Grid><br />
      <Grid item ><Typography align='left' component='div'><Box letterSpacing={1} lineHeight={1.2} pt={{ xs: 1.5 }} pb={{ xs: 1 }}>イートラスト株式会社:2018/01 ~ 2020/01</Box></Typography></Grid >
      <Grid item>
        <Typography align='left' component='div' ><Box className='text' mt={1} component='p'>
          上記約2年間、WEB制作会社でWEBデザイナー/マークアップエンジニアとして80社以上のホームページを制作。<br />
          特にSCSSとJavaScript(jQuery)を使用した効率的なスタイリング、フロント表現を得意としています。<br />
          この期間にPythonやGoogleAppScriptを使用した、業務効率化ツールの作成もしています。
        </Box></Typography>
      </Grid>
    </Grid >
  );
}
function Discribe() {

  return (
    <Box className="skillsWrap" pt={{ xs: 1, sm: 1, md: 0 }}>
      <Skills />
    </Box>
  );
}
function PagenationFunc(props) {
  return (
    <Pagination page={props.count} className="pagenation" count={props.pageAmount} onChange={(event, value) => props.onChange(event, value)} />

  );

}
class Works extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
    }
  }
  handlePage = (event, value) => {
    this.setState({
      page: value,
    })
    // const target = document.getElementsByClassName('pageNo'+value);
    // for (var i=0;i<target.length;i+=1){
    //     target[i].style.display = 'block';
    // }
  }
  handleDisp = (pageNo) => {
    return (pageNo == 'pageNo' + this.state.page ? true : false);
  }
  render() {
    const pageAmount = Math.floor(works.length / 9);
    const startCard = (this.state.page - 1) * 10;
    // const dispWorks = works.slice(startCard, startCard + 9);
    const dispWorks = works;
    return (
      < Box px={2} >
        <PagenationFunc count={this.state.page} pageAmount={pageAmount} onChange={(event, value) => this.handlePage(event, value)} />
        <Grid container spacing={2} className='cardWrap'>
          {dispWorks.map((value, i) => {
            const filePath = value.filePath;
            const title = value.clientName;
            const pageNo = 'pageNo' + (Math.floor(i / 9) + 1);

            return <WorkBox key={i} fadeState={this.handleDisp(pageNo)} index={i} clientName={value.clientName} filePath={value.filePath} date={value.makeDate.substr(0, 10)} onClick={() => this.props.onClick(filePath, title, dispWorks, i)} />
          })}
        </Grid>
        <PagenationFunc count={this.state.page} pageAmount={pageAmount} onChange={(event, value) => this.handlePage(event, value)} />
      </Box >

    );

  }
}
function WorkBox(props) {
  return (
    <Fade in={props.fadeState} timeout={500} style={{ display: !props.fadeState ? 'none' : 'block' }} >
      < Grid item xs={12} s={2} md={4} key={props.clientName} >
        {
          ImgMediaCard(props.filePath, props.clientName, props.date, props.onClick)
        }
      </Grid >
    </ Fade>
  );
}



class Skills extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      useSkills: {
        html: true,
        css: true,
        js: true,
        jq: true,
        sass: true,
        php: true
      },
      skills: [
        'html',
        'css',
        'js',
        'sass',
        'php',
        'photoshop',
        'jq',
      ]
    }
  }
  render() {
    const skills = this.state.skills;
    return (
      <ul className='skillList'>
        {
          skills.map(
            (skill, index) => {
              const path = './skillSvg/' + skill + '.svg';
              return (
                <li key={index}><img height={64} alt={skill} src={path} /></li>
              );
            }
          )
        }
      </ul>
    );
  }
}
function Contents() {

  const [open, setOpen] = useState(false);
  const [imgPath, setImgPath] = useState('');
  const [title, setTitle] = useState('');
  const [works, setWorks] = useState('');
  const [current, setCurrent] = useState('');
  const classes = useStyles();
  const modalFire = () => {

  }
  const handleModal = (img, title, works, index) => {
    setImgPath(img);
    setTitle(title);
    setCurrent(index);
    setWorks(works);
    setOpen(
      open === true ? false : true
    );
  }
  const handleClose = () => {
    setOpen(false);
  };
  const handlePrev = () => {
    setCurrent(current - 1);
    setImgPath(works[current - 1].filePath);
  }
  const handleNext = () => {
    setCurrent(current + 1);
    setImgPath(works[current + 1].filePath);
  }
  // const aaa = imgPath;
  return (
    <Box pt={[1, 1, 4]} className={classes.contents} >
      <Works onClick={(img, title, works, index) => handleModal(img, title, works, index)} />

      <Modal
        className={classes.modalWrap}
        open={open}
        // onBackdropClick={handleClose}
        closeAfterTransition={true}
        onRendered={modalFire}
        BackdropProps={{
          timeout: 100,
        }}
      >
        <Fade in={open} timeout={800}>
          <Box align='center'>
            <Box className='modalWrap' >
              <img src={imgPath} alt={title} width='80%' />
              {current !== 0 &&
                <Button className='prev' onClick={handlePrev}><ArrowForwardIosIcon /></Button>
              }
              {current !== works.length - 1 &&
                <Button className='next' onClick={handleNext}><ArrowForwardIosIcon /></Button>
              }

              <CancelIcon className='closeIcon' onClick={handleClose} fontSize='large' htmlColor='white'></CancelIcon>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </Box >
  );
}

export default App;
