import works from './output'

export default function preload(){
    works.map((value,i)=>{
        var img = document.createElement('img');
        img.src = value.filePath;
        return false
    });
}

